import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import {CommonLayoutComponent} from '../../../layouts/common-layout/common-layout.component';
import {DashboardService} from '../../../dashboard/dashboard.service';
import {currentPathInterface, rightBarUserDetails, unreadCountData} from '../../../dashboard/dashboardInterfaces/paht.type';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.css']
})

export class SideNavComponent{
    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;
    isExpand : boolean;
    hideSideNav = false;
    userData: rightBarUserDetails;
    currentPathDetails: currentPathInterface;
    unreadMessageFlag: boolean = false;
    unreadCountData: unreadCountData;
    constructor( private themeService: ThemeConstantService,
                 private commonLayout: CommonLayoutComponent,
                 private service: DashboardService,
                 private gtmService: GoogleTagManagerService) {
      this.userData = this.service.userData;
      this.service.currentPathSubject.subscribe(data => this.currentPathDetails = data)
      this.service.userTokensSubject.subscribe(data => this.userData.token = String(data))
      this.getCurrentPath();
      this.service.currentUserSubject.subscribe(data => this.userData = data);
    }

    getCurrentPath(){
      this.currentPathDetails = this.service.getCurrentPath();
    }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
    this.hideSideNav = true;
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
    this.hideSideNav = false;
  }
  getUnreadMsgService() {
    this.service.getAllPathsThreadUnreadCounts().subscribe(data => {
      if(data && data.total_threads_count_which_contains_unread_messages && data.total_threads_count_which_contains_unread_messages > 0) {
        this.unreadMessageFlag = true;
        this.unreadCountData = data;
        // Store data in session storage
        sessionStorage.setItem('unreadMessageData', JSON.stringify(data.total_threads));
      } else {
        this.unreadMessageFlag = false;
        // Clear session storage if there is no unread message
        sessionStorage.removeItem('unreadMessageData');
      }
    })
  }
    ngOnInit(): void {
      this.getUnreadMsgService(); // get unread messages initially
      setInterval(() => {
        this.getUnreadMsgService(); // then get unread messages by interval of 5 seconds
      }, 5000);
      this.menuItems = ROUTES.filter(menuItem => menuItem);
      this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
      this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
      this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
      this.commonLayout.showMenuBtn = true;
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }

    closeMenu(){
      this.commonLayout.toggleExpand();
      this.commonLayout.showMenuBtn = true;
    }

    logout(){
      this.service.logout();
    }

    adminLogin(){
        this.service.adminLogin();
    }

    pushGTMTag(event: string): void {
      /** gtm - navigation events */
      this.gtmService.pushTag({ event });
    }
}
