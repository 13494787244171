import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    let html = value.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    return this.sanitized.bypassSecurityTrustHtml(html);
  }
} 