import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { AuthenticationService } from './authentication.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ShopifyService {
checkoutId: BehaviorSubject<any> = new BehaviorSubject("")
  // url = `http://localhost:3000`
  url = 'https://app.learningverse.ai/shopify'
  constructor(private http: HttpClient, private userService: AuthenticationService) { }

  createCheckout(userDetails: UserDetails){
    let url = `${this.url}/${userDetails.tier}`
    let user = JSON.parse(localStorage.getItem('userData'))
    let data = {

      token: user.token,
      id: user.id
    }

    return this.http.post(url,data, {
      headers: {
        'x-api-key': environment.SHOPIFY_API_KEY
    }})

  }

  getCheckout(id){
    return this.http.post(`${this.url}/checkout`,{
      checkout_id: id
    }, {
      headers: {
        'x-api-key': environment.SHOPIFY_API_KEY
    }}, )
  }

  checkIfPaid(){}

  getProducts(){}

  getCheckoutStatus(){
    return this.http.post(`${this.url}/checkout-status`,{
      token: JSON.parse(localStorage.getItem('userData')).token,
      id: JSON.parse(localStorage.getItem('userData')).id,
      checkout_id: localStorage.getItem('checkoutId')
    }, {
      headers: {
        'x-api-key': environment.SHOPIFY_API_KEY
    }})
  }

  upgradeTier(){}


}
export interface UserDetails{
  firstName?: string;
  lastName?: string;
  email: string;
  tier: string;
}
