import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  router = inject(Router)
  constructor(private dashboardService: DashboardService) { 
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (response.body.statusCode == 401) {
            console.log("Unauthorized");
            this.dashboardService.logout();
          }
        }
      })
    );
  }
}
