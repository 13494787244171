import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  allPathMails,
  apiResponse,
  allPathMailsThreadsUnreadCounts,
  breakouts,
  breakoutUserStatus,
  createPathMail,
  currentPath,
  currentPathInterface,
  discussions,
  getPathMates,
  login,
  pathContentComment,
  pathDetail,
  pathView,
  rightBar,
  rightBarUserDetails,
  rightBarUserDCDetails,
  rightBarUserGTKDetails,
  singleBreakout,
  thread,
  userPaths,
  userProfile,
  editUserDC,
  editUserGTK,
  userGTKProfileI,
  userDCProfileI
} from './dashboardInterfaces/paht.type';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WherebyService } from '../shared/services/whereby.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  authToken: string = '';
  userData: rightBarUserDetails;
  userDCData: rightBarUserDCDetails;
  userGTKData: rightBarUserGTKDetails;
  currentPathSubject = new Subject<currentPathInterface>();
  userTokensSubject = new Subject<number>();
  currentUserSubject = new Subject<rightBarUserDetails>();
  activtySubmitedSubject = new Subject<boolean>();
  constructor(private http: HttpClient, private router: Router, private whereby: WherebyService) {
    if (this.isLoggedIn()) {
      var data: rightBarUserDetails = JSON.parse(
        localStorage.getItem('userData')
      );
      this.authToken = data.token;
      this.userData = data;     
      

      var userDCData: rightBarUserDCDetails = {
          'cityState'  : '',
          'workEmail'  : '',
          'workPhone'  : '',
          'shortBio'   : '',
          'hEducation' : ''
      }
      this.userDCData = userDCData;          

      var userGTKData: rightBarUserGTKDetails = {
          'quote'       : '',
          'coaching'    : "",
          'mentor'      : '',
          'inspiration' : '',
          'superpower'  : "",
          'linkedIn'    : ''
      }  
      this.userGTKData = userGTKData;      

    }
  }

  getLoggedInUser() {
    var data: rightBarUserDetails = JSON.parse(
      localStorage.getItem('userData')
    );
    return data;
  }

  //v3/users/paths/
  getAllPaths() {
    return this.http.get<userPaths>(environment.API_Path + 'v3/users/paths/', {
      params: this.attachToken(),
    });
  }

  //v2/pathmail/total-threads-unread-counts/
  getAllPathsThreadUnreadCounts() {
    return this.http.get<allPathMailsThreadsUnreadCounts>(
      environment.API_Path + 'v2/pathmail/total-threads-unread-counts/',
      { params: this.attachToken() }
    );
  }

  //v3/users/currentPath/
  currentPath() {
    return this.http.get<currentPath>(
      environment.API_Path + 'v3/users/currentPath/',
      { params: this.attachToken() }
    );
  }

  //v2/feed/
  rightBar() {
    return this.http.get<rightBar>(environment.API_Path + 'v2/feed/', {
      params: this.attachToken(),
    });
  }

  //v3/gtk-profile/
  gtkProfile(id) {
    return this.http.get<userGTKProfileI>(environment.API_Path + 'v3/users/gtk-profile/' + id + '/', {
      params: this.attachToken(),
    });
  }

  //v3/dc-profile/
  dcProfile(id) {
    return this.http.get<userDCProfileI>(environment.API_Path + 'v3/users/dc-profile/' + id + '/', {
      params: this.attachToken(),
    });
  }


  breakoutUserStatuses(id: number) {
    return this.http.get<breakoutUserStatus>(
      environment.API_Path +
      'v2/events/' +
      id +
      '/invitees/?id=' +
      id +
      '&token=' +
      this.authToken
    );
  }

  getInvitees(eventId: number) {
    return this.http.get<any>(environment.API_Path + `v2/events/${eventId}/invitees/?token=${this.authToken}`);
  }

  //v3/paths/:id/
  pathViewDetails(id: number) {
    return this.http.get<pathDetail>(
      environment.API_Path + 'v3/paths/' + id + '/',
      { params: this.attachToken() }
    );
  }

  //v3/paths/:id/phases/
  pathViewSteps(id: number) {
    return this.http.get<pathView>(
      environment.API_Path + 'v3/paths/' + id + '/phases/',
      { params: this.attachToken() }
    );
  }

  //v2/users/pathmates/
  getPathMates() {
    return this.http.get<getPathMates>(
      environment.API_Path + 'v2/users/pathmates/',
      { params: this.attachToken() }
    );
  }

  // v2/events/users
  fetchUsersOfPath(pathID: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('token', this.authToken);
    queryParams = queryParams.append('path', pathID);

    return this.http.get<createPathMail>(
      environment.API_Path +
      'v2/events/users/?token=' +
      this.authToken +
      '&path=' +
      pathID
    );
  }

  ///api/v2/users/:id/pathfolio/
  getUserProfile(id: number) {
    return this.http.get<userProfile>(
      environment.API_Path + 'v2/users/' + id + '/pathfolio/',
      { params: this.attachToken() }
    );
  }

  ///api/v2/pathmail/threads/
  getAllThreads() {
    return this.http.get<allPathMails>(
      environment.API_Path + 'v2/pathmail/threads/',
      { params: this.attachToken() }
    );
  }

  // /v2/pathmail/users-paths/
  threadGetUsersPaths() {
    return this.http.get<createPathMail>(
      environment.API_Path + 'v2/pathmail/users-paths/',
      { params: this.attachToken() }
    );
  }

  // /2/discussions/posts/'
  getDiscussionList() {
    return this.http.get<discussions>(
      environment.API_Path + 'v2/discussions/posts/',
      { params: this.attachToken() }
    );
  }

  login(postData) {
    let body = new URLSearchParams();
    body.set('email', postData.email);
    body.set('password', postData.password || null);
    body.set('login_with_otp', postData.login_with_otp ||  false);
    body.set('entered_otp', postData.entered_otp || null);
    
    return this.http.post<login>(environment.API_Path + 'v2/login/', body, {
      headers: this.postHeaders(),
    });
  }

  reset(email: string) {
    let body = new URLSearchParams();
    body.set('email', email);

    return this.http.post<any>(
      environment.API_Path + 'v2/users/reset-password/',
      body,
      { headers: this.postHeaders() }
    );
  }

  editProfile(ProfileData: UntypedFormGroup, file: any) {
    let body = new FormData();
    body.append('firstName', ProfileData.controls['firstName'].value);
    body.append('lastName', ProfileData.controls['lastName'].value);
    body.append('company', ProfileData.controls['company'].value);
    body.append('profession', ProfileData.controls['profession'].value);
    body.append('education', ProfileData.controls['education'].value);
    body.append('email', ProfileData.controls['email'].value);
    body.append('zip', ProfileData.controls['zipCode'].value);
    if (file) body.append('file', file);

    // var data = new FormData();
    // data.append("firstName", "a");
    // data.append("lastName", "v");
    // data.append("company", "");
    // data.append("profession", "");
    // data.append("education", "");
    // data.append("email", "daniel.beller07@gmail.com");
    // data.append("zip", "");
    // data.append("file", file, "/C:/Users/mkash/Desktop/delme/icon-profile.png");

    return this.http.post<login>(
      environment.API_Path + 'v2/users/edit-profile/',
      body,
      {
        // headers:
        //   new HttpHeaders({
        //     'Content-Type': 'multipart/form-data;',
        //     'Accept': '*/*',
        //   }),
        params: this.attachToken(),
      }
    );
  }

  editDCProfile(ProfileData: UntypedFormGroup) {
    let body = new FormData();
    body.append('cityState', ProfileData.controls['cityState'].value);
    body.append('workEmail', ProfileData.controls['workEmail'].value);
    body.append('workPhone', ProfileData.controls['workPhone'].value);
    body.append('shortBio', ProfileData.controls['shortBio'].value);
    body.append('hEducation', ProfileData.controls['hEducation'].value);

    return this.http.post<editUserDC>(
      environment.API_Path + 'v2/users/edit-dc-profile/',
      body,
      {
        params: this.attachToken(),
      }
    );
  }

  editGTKProfile(ProfileData: UntypedFormGroup) {
    let body = new FormData();
    body.append('quote', ProfileData.controls['quote'].value);
    body.append('coaching', ProfileData.controls['coaching'].value);
    body.append('mentor', ProfileData.controls['mentor'].value);
    body.append('inspiration', ProfileData.controls['inspiration'].value);
    body.append('superpower', ProfileData.controls['superpower'].value);
    body.append('linkedIn', ProfileData.controls['linkedIn'].value);

    return this.http.post<editUserGTK>(
      environment.API_Path + 'v2/users/edit-gtk-profile/',
      body,
      {
        params: this.attachToken(),
      }
    );
  }


  signup(body: FormData) {
    return this.http.post<any>(environment.API_Path + 'v2/users/', body, {
      // headers:
      //   new HttpHeaders({
      //     'Content-Type': 'multipart/form-data;',
      //     'Accept': '*/*',
      //   }),

    });
  }

  addDiscussion(comment: string) {
    let body = new FormData();
    body.set('message', comment);
    body.set('attachmentType', 'text');
    // body.set('attachmentType', 'text');

    return this.http.post<any>(
      environment.API_Path + 'v2/discussions/posts/',
      body,
      { headers: this.postHeadersFormData(), params: this.attachToken() }
    );
  }

  switchPath(pathID: number) {
    return this.http.get<any>(
      environment.API_Path + 'v2/paths/' + pathID + '/start/',
      { params: this.attachToken() }
    );
  }

  loadComments(pathID: number) {
    return this.http.get<pathContentComment>(
      environment.API_Path + 'v2/actions/' + pathID + '/submits/',
      {
        params: this.attachToken().set('page', 1).set('limit', 25),
      }
    );
  }

  submitComment(
    pathID: number,
    msg: string,
    attachmentType: string,
    file: any,
    mode: boolean
  ) {
    let body = new FormData();
    body.set('comment', msg);
    body.set('attachmentType', attachmentType);
    if (file) body.set('file', file);
    let url = mode
      ? environment.API_Path + 'v2/actions/' + pathID + '/submit/private/'
      : environment.API_Path + 'v2/actions/' + pathID + '/submit/';

    return this.http.post<any>(url, body, {
      params: this.attachToken(),
    });
  }

  submitReply(
    commentID: number,
    msg: string,
    attachmentType: string,
    file: any,
    mode: boolean = false
  ) {
    let body = new FormData();
    body.set('message', msg);
    body.set(
      'attachmentType',
      attachmentType == undefined ? 'text' : attachmentType
    );
    if (file) body.set('file', file);
    // let url = mode ? environment.API_Path+'v2/actions/'+pathID+'/submit/private/' : environment.API_Path+'v2/actions/'+pathID+'/submit/';

    return this.http.post<any>(
      environment.API_Path + 'v2/submits/' + commentID + '/comments/',
      body,
      {
        params: this.attachToken(),
      }
    );
  }

  submitReplyToAnnouncement(
    commentID: number,
    msg: string,
    attachmentType: string,
    file: any
  ) {
    let body = new FormData();
    body.set('message', msg);
    if (file) {
      body.set('attachmentType', attachmentType);
      body.set('file', file);
    }

    return this.http.post<any>(
      environment.API_Path + 'v2/discussions/posts/' + commentID + '/replies/',
      body,
      {
        params: this.attachToken(),
      }
    );
  }

  deleteComment(commentID: number) {
    return this.http.delete<any>(
      environment.API_Path + 'v2/submits/' + commentID + '/',
      { params: this.attachToken() }
    );
  }

  deleteReply(commentID: number) {
    return this.http.delete<any>(
      environment.API_Path + 'v2/discussions/posts/' + commentID + '/',
      { params: this.attachToken() }
    );
  }

  getThreadDetails(threadID: number) {
    return this.http.get<thread>(
      environment.API_Path + 'pathmail/threads/' + threadID + '/',
      { params: this.attachToken() }
    );
  }

  postThread(threadID: number, msg: string, attachmentType: string, file: any) {
    let body = new FormData();
    body.set('message', msg);
    body.set('attachmentType', attachmentType);
    if (file) body.set('file', file);

    return this.http.post<thread>(
      environment.API_Path + 'pathmail/threads/' + threadID + '/',
      body,
      { params: this.attachToken() }
    );
  }

  addBreakout(body: FormData) {



    return this.http.post<any>(environment.API_Path + 'v2/events/', body, {
      params: this.attachToken(),
    });
  }

  getAllBreakouts() {
    return this.http.get<breakouts>(environment.API_Path + 'v2/events/', {
      params: this.attachToken(),
    });
  }

  getSingleBreakout(id: number) {
    return this.http.get<singleBreakout>(
      environment.API_Path + 'v2/events/' + id + '/',
      { params: this.attachToken() }
    );
  }

  updateBreakout(id: number, data: any, file: any, meeting?: any) {
    const formData = new FormData()
    console.table(meeting)
    formData.set("address", data.location)
    formData.set("description", data.desc)
    formData.set("title", data.name)
    formData.set("path", data.path.id)
    formData.set("start_date", data.start_date)
    formData.set("end_date", data.end_date)
    formData.set('eventType', data.eventType)
    if(!meeting){
      formData.set("roomUrl", data.room_url)
      formData.set("hostRoomUrl", data.host_room_url)
      formData.set("meetingId", data.meeting_id)
      formData.set("viewerUrl", data.viewer_url)
    }

    else{
      alert('meetinfound' + meeting.room_url)
      formData.set("roomUrl", meeting.room_url)
      formData.set("hostRoomUrl", meeting.host_room_url)
      formData.set("meetingId", meeting.meeting_id)
      formData.set("viewerUrl", meeting.viewer_url)
    }
    let inv = '';
    if (data.listOfAttendees) {

      data.listOfAttendees.forEach(element => {
        formData.append('invitees[]', element)
      });

    }
    if (file) {
      formData.set("file", file)
    }

    return this.http.post(`${environment.API_Path}v2/events/${id}/?token=${this.authToken}`, formData)

  }

  changeBreakoutStatus(id: number, eventID: number) {
    var body = new FormData();
    body.set('token', this.authToken);
    body.set('status', id.toString());

    return this.http.post<singleBreakout>(
      environment.API_Path + 'v2/events/' + eventID + '/status/',
      body,

    );
  }

  getAllCommentsBreakout(id: number) {
    return this.http.get<pathContentComment>(
      environment.API_Path + 'v2/events/' + id + '/comments/',
      {
        params: this.attachToken().set('page', 1).set('limit', 25),
      }
    );
  }

  submitReplyInBreakOut(
    breakoutID: number,
    msg: string,
    attachmentType: string,
    file: any
  ) {
    let body = new FormData();
    body.set('message', msg);
    body.set(
      'attachmentType',
      attachmentType == undefined ? 'text' : attachmentType
    );
    if (file) body.set('file', file);

    return this.http.post<any>(
      environment.API_Path + 'v2/events/' + breakoutID + '/comments/',
      body,
      {
        params: this.attachToken(),
      }
    );
  }

  submitCreateThread(data: FormData) {
    return this.http.post<any>(
      environment.API_Path + 'v2/pathmail/threads/',
      data,
      { params: this.attachToken() }
    );
  }

  checkAccessCode(code: any) {
    var body = new FormData();
    body.append('accessCode', code);

    return this.http.post<any>(
      environment.API_Path + 'v2/paths/access_code/',
      body,
      { params: this.attachToken(), headers: this.postHeadersFormData() }
    );
  }

  attachToken() {
    return new HttpParams().set('token', this.authToken);
  }

  reFormatDate(unixTimeStamp: string) {
    return new Date(unixTimeStamp).toLocaleString('en-US');
  }

  setSession(userData: rightBarUserDetails) {
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  setCurrentPath(currentPath: currentPathInterface) {
    localStorage.setItem('currentPath', JSON.stringify(currentPath));
  }

  getCurrentPath() {
    var data: currentPathInterface = JSON.parse(
      localStorage.getItem('currentPath')
    );
    return data;
  }

  logout() {
    this.deleteSession();
    this.router.navigateByUrl('/authentication/login')
    sessionStorage.removeItem('unreadMessageData');
  }

  adminLogin() {
    this.deleteSession();
    window.location.href = `${environment.ADMIN_PATH}/app.php/admin/login`;
  }

  deleteSession() {
    localStorage.removeItem('userData');
    localStorage.removeItem('currentPath');
  }

  isLoggedIn() {
    return localStorage.getItem('userData') !== null;
  }

  postHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
    });
  }

  postHeadersFormData() {
    return new HttpHeaders({
      // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
    });
  }

  imgFix(img: string) {
    if (!img) {
      return '/assets/images/avatars/user-light.png'
    }

    if (img && img.indexOf('test.test') != -1) {
      return `https://test.unleeshadmin.com/event_icons/` + img.split('/')[img.split('/').length - 1]
    }

    let im = img
      ? img.replace('8288', '')
      : null;

    return im;
  }


  async getPathIcon(id: any) {



  }

  getProgramData() {
    return this.http.get(`https://app.learningverse.ai/app.php/api/v2/programdata/`)
  }

  getLastSchoolsData() {
    return this.http.get(`https://app.learningverse.ai/app.php/api/v2/getlastschooldata/`)
  }

  getToken() {
    return this.authToken;
  }

  getId(){
    return this.userData.id
  }

  getUserData(){
    let token = JSON.parse(localStorage.getItem('userData')).token;
    return this.http.get(`${environment.API_Path}v2/users/${this.getId()}/?token=${token}`)
  }

  getFeedByPage(page: number) {
    return this.http.get(`${environment.API_Path}v2/feed/?token=${this.authToken}&page=${page}`)
  }

  sendOtp(formData) {
    let body = new URLSearchParams();
    body.set('email', formData.email);
    
    return this.http.post<apiResponse>(environment.API_Path + 'v2/login_otp/', body, {
      headers: this.postHeaders(),
    });
  }

}
