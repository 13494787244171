import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class WherebyService {
  url =  '/v1/meetings/';
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${environment.WHEREBY_API}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
    'Access-Control-Allow-Credentials': 'true'
  });
  constructor(private http: HttpClient) {
    console.log(environment.production, "is Production")
  }
  getWherebyUrl() { }

  createRoom() {

    let body = {
      roomNamePattern: "uuid",
      roomMode: "group",
      startDate: "2019-08-24T14:15:22Z",
      endDate: "2024-08-24T14:15:22Z",
      startTrigger: "none",
      fields: [
        "hostRoomUrl"
      ]
    }
    return this.http.post(`${this.url}`, body, { headers: this.headers });
  }

  getRoom(meetingId: string) {
    return this.http.get(`${this.url}` + meetingId, { headers: this.headers });
  }

  deleteRoom(meetingId: string) {
    return this.http.delete(`${this.url}${meetingId}`, { headers: this.headers })
  }

  getInsights() { }
}


export interface Meeting {
  meetingId: string;
  roomUrl: string;
  roomName: string;
}
