// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_Path: "https://test.unleeshadmin.com/api/",
  API_Path: 'https://app.learningverse.ai/app.php/api/',
  //API_Path: 'https://lvsandbox.com/app.php/api/',
  WHEREBY_API: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmFwcGVhci5pbiIsImF1ZCI6Imh0dHBzOi8vYXBpLmFwcGVhci5pbi92MSIsImV4cCI6OTAwNzE5OTI1NDc0MDk5MSwiaWF0IjoxNjgyNjg5MDQyLCJvcmdhbml6YXRpb25JZCI6MTgyMDU1LCJqdGkiOiI3MDU0ZTcxYS1iNmJkLTRlYzQtYmFkZi1lYzJmNDNiMDBlOTIifQ.Jxfpn7aEjs23oZO-IUOrqqXFuQSqlanmads4qigxYRY`,
  SHOPIFY_API_KEY: '9bb00e78d2f2f3312803a7edb3ddc096b6b52afb61df7f80456ca17e298414c9',
  GOOGLE_TAG_MANAGER_KEY: 'GTM-W57PTH8',
  ADMIN_PATH: 'http://lvsandbox.com:8882'
  // ADMIN_PATH: 'http://app.learningverse.ai:8882/'
  // TESTAPI: "https://test.unleeshadmin.com/api/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
