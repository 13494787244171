import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = []
// export const ROUTES: SideNavInterface[] = [
//   {
//     path: '',
//     title: 'Unleesh Features',
//     iconType: 'nzIcon',
//     iconTheme: 'outline',
//     icon: 'appstore',
//     submenu: [
//       {
//         path: 'dashboard/pathmates',
//         title: 'Pathmates',
//         iconType: 'nzIcon',
//         iconTheme: 'outline',
//         icon: 'user',
//         submenu: []
//       },
//     ]
//   }
// ]
