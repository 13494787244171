import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../interfaces/user.type';

const USER_AUTH_API_URL = '/api-url';

@Injectable()
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(USER_AUTH_API_URL, { username, password })
        .pipe(map(user => {
          console.warn(user)
            if (user && user.token) {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                console.warn(this.currentUserSubject.value)
            }
            return user;
        }));
    }

    logout() {
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem('unreadMessageData');
        this.currentUserSubject.next(null);
    }

    getUser() {
        return this.currentUserSubject.value;
    }


}
