import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataLayerService } from './core/services/data-layer.service';
import {  GoogleAnalyticsService } from 'ngx-google-analytics';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
declare let gtag: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{

  constructor(public router: Router, protected $gaService: GoogleAnalyticsService, private gtmService: GoogleTagManagerService){

  }

  ngOnInit(): void {
    this.$gaService.pageView('home', '/home', undefined, { user_id: 'user_id' });
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
