import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import { FullLayout_ROUTES } from "./shared/routes/full-layout.routes";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import {AuthGuard} from './shared/services/auth.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/paths/default',
    pathMatch: 'full',
  },

    {
        path: '',
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: FullLayout_ROUTES
    },

    // {
    //   path: 'landing',
    //   loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
    // },


    {
      path: 'thank-you',
      loadChildren: () => import('./modules/thank-you/thank-you.module').then(m => m.ThankYouModule)
    },
    {
      path: 'activate-user',
      loadChildren: () => import('./modules/activate-user/activate-user.module').then(m => m.ActivateUserModule)
    },
    {
      path: 'set-password',
      loadChildren: () => import('./modules/set-password/set-password.module').then(m => m.SetPasswordModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            useHash: true
        })
    ],
    exports: [
        RouterModule
    ],
  providers: [AuthGuard]
})

export class AppRoutingModule {
}
